@import '../components/rating/rating.mobile';
@import '../components/comments/comments.mobile';
@import '../components/review-comment/review-comment.mobile';
@import '../components/review-actions/styles/review-actions.mobile';

.#{$pdp-prefix-reviews} {
  width: 100%;

  &__header {
    margin-bottom: $andes-spacing-24;
    padding-left: $andes-spacing-16;
    padding-right: $andes-spacing-16;

    &__title {
      font-size: $font-size-20;
      font-weight: $font-weight-regular;
      line-height: $line-height-base-flat;
    }
  }

  &__comments__tab-content {
    margin-top: $andes-spacing-24;
  }
}

.#{$pdp-prefix-reviews}__empty-reviews {
  .#{$pdp-prefix-reviews} {
    &__actions__container {
      padding-left: 0;
    }

    &__header {
      margin-bottom: 0;
    }

    &__rating {
      margin-bottom: 0;
    }
  }
}
