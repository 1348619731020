$onboarding-header-bg-color: #eee;
$onboarding-image-border-color: color(color_black_10000);

.onboarding {
  &__modal {
    max-width: calc(100% - 48px) !important; // Needed to override andes inline with that only suports pixels

    .meli-usd-modal-first-info-row {
      .ui-pdp-icon--mcoin-buy {
        margin-right: 6px;
      }

      .ui-pdp-icon--mcoin-sell {
        margin-right: 6px;
      }
    }

    .andes-modal__content {
      padding: 24px;
    }
  }

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .#{$pdp-prefix}-container__row {
      justify-content: center;
      margin: 0;
      width: 100%;

      &--push-notification- {
        &message {
          margin-top: 22px;
        }

        &actions {
          margin-top: 22px;
        }
      }
    }

    .#{$pdp-prefix}-media {
      margin-bottom: 20px;
      width: 100%;

      &__body {
        text-align: left;
      }

      &__title {
        line-height: 22px;
      }

      &__text {
        line-height: 18px;
      }
    }

    .#{$pdp-prefix}-actions {
      padding: 0;
      width: 100%;
    }

    .meli-usd-modal-title {
      .andes-money-amount {
        display: inline-flex;
      }
    }
  }

  &__modal.full-modal {
    .ui-vpp-image-component {
      height: 30px;
      margin: 0;
    }

    .full-image {
      margin-top: 16px;
    }
  }

  &__modal.full-modal-fs {
    max-height: 33em;

    .ui-vpp-image-component {
      height: 30px;
      margin: 0;
    }

    .ui-pdp-container__row {
      &--full-first-info-row,
      &--full-second-info-row,
      &--full-third-info-row {
        .ui-pdp-icon {
          width: 32px;
          margin-right: 16px;
        }
      }
    }

    .full-image {
      margin-top: 16px;
    }
  }

  &__modal.mcoin-modal {
    .ui-pdp-actions {
      margin-top: 10px;
    }

    .up-pdp-media__body {
      text-align: center;
    }

    .mcoin-second-info-row,
    .mcoin-third-info-row {
      width: fit-content;

      &.mcoin-second-info-row {
        margin-top: 5px;
      }

      .ui-pdp-media__body {
        margin-left: 8px;
      }
    }
  }

  &__modal.cbt-modal {
    .andes-modal__content {
      padding-top: 32px;
    }

    .cbt-image-label {
      align-items: center;
      justify-content: center;
      margin-bottom: 0;

      .ui-pdp-media__body {
        flex: unset;
        margin-left: 10px;
      }
    }

    .ui-pdp-media {
      &__title--plain,
      &__text {
        line-height: 22px;
      }
    }

    .#{$pdp-prefix}-icon {
      &--cbt-world {
        width: 23.4px;
        height: 23.4px;
      }

      &--cbt-summary {
        width: 198px;
      }
    }
  }

  &__modal.cbt-modal--short {
    min-height: 308px;
  }

  &__modal.loan-summary-modal {
    .loan-summary-label {
      line-height: 1.3;
      margin-bottom: 20px;
    }

    .loan-summary-image {
      margin: 0;
    }
  }

  &__modal.external-credits-modal {
    max-height: 324px;
    max-width: calc(100% - 48px);

    .external-credits-modal-image {
      margin: 0 auto;

      svg {
        width: 105px;
        height: 80px;
      }

      .external-credits-new-onboarding {
        // sass-lint:disable no-qualifying-elements //
        img {
          width: auto;
          height: auto;
          margin-left: 5px;
        }
      }

      .ui-pdp-icon--external-credits-mc-onboarding {
        img {
          width: 240px;
          height: 115px;
        }
      }
    }

    .#{$pdp-prefix}-container__row {
      p {
        line-height: 1.25;
      }

      &--external-credits-modal-title {
        padding-top: 14px;
      }

      &--external-credits-modal-subtitle {
        padding-top: 10px;
      }

      &--modal-actions {
        padding-top: $andes-spacing-28;
      }
    }
  }

  &__modal.external-credits-mc-modal {
    max-height: 324px;

    .external-credits-modal-image {
      margin: 0 auto $andes-spacing-24 auto;

      img {
        width: auto;
        height: 115px;
      }
    }

    .external-credits-modal-title {
      margin-bottom: $andes-spacing-8;
    }

    .external-credits-modal-subtitle {
      margin-bottom: $andes-spacing-24;
    }
  }

  &__modal.push-notification-question-modal {
    .#{$pdp-prefix}-container {
      display: block;
      width: 100%;
      height: 100%;
    }

    .andes-modal__content {
      padding: 0;
      width: 100%;

      .#{$pdp-prefix}-container__row--push-notification-title {
        margin: 22px 22px 0;
        width: auto;
      }

      .#{$pdp-prefix}-container__row--push-notification-image {
        margin-top: 16px;
        max-width: 100%;
        display: block;
        box-sizing: content-box;
        object-fit: contain;

        img {
          max-width: 100%;
          border: 1px solid $onboarding-image-border-color;
        }
      }

      .#{$pdp-prefix}-container__row--push-notification-message {
        margin-top: 22px;
        padding-left: 22px;
        padding-right: 22px;
      }

      .#{$pdp-prefix}-container__row--push-notification-actions {
        margin-top: 32px;
        padding-left: 22px;
        padding-right: 22px;

        .andes-button {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &__modal.push-notification-bookmark-modal {
    min-height: 500px;
    width: 100%;
    margin: 0 24px;

    .andes-modal__content {
      padding: 0;
      width: 100%;

      .#{$pdp-prefix}-container__row--push-notification-header {
        padding: 22px 22px 0;
        background-color: $onboarding-header-bg-color;

        .ui-vpp-onboarding-header {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .ui-vpp-image-component {
          margin-top: 12px;
        }
      }

      .#{$pdp-prefix}-container__row--push-notification-message {
        margin-top: 22px;
        padding-left: 22px;
        padding-right: 22px;
      }

      .#{$pdp-prefix}-container__row--push-notification-actions {
        margin-top: 32px;
        padding-left: 22px;
        padding-right: 22px;

        .andes-button {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &__modal.contacts-fraud-modal {
    max-width: calc(100% - 40px);

    .contacts-fraud-icon-modal {
      margin-block: $andes-spacing-8 $andes-spacing-32;
    }

    .andes-modal__content {
      padding: $andes-spacing-24 $andes-spacing-28;
    }

    .contacts-fraud-modal-title {
      line-height: 1.25;
      margin-bottom: $andes-spacing-16;
    }

    .contacts-fraud-modal-text-list {
      margin-bottom: $andes-spacing-32;
    }
  }

  &__modal.long-term-rental-modal {
    max-height: 455px;
    max-width: calc(100% - 48px);

    .long-term-rental-modal-image {
      margin: 0 auto;
    }

    .#{$pdp-prefix}-container__row {
      p {
        line-height: $line-height-s;
      }

      &--long-term-rental-modal-title {
        padding-top: $andes-spacing-20;
      }

      &--long-term-rental-modal-subtitle {
        padding-top: 10px;
      }

      &--modal-actions {
        padding-top: 35px;
      }
    }

    @media (width <= 320px) {
      min-height: 342px;
    }
  }
}

.meli-usd-modal-title {
  .ui-pdp-price__part .andes-money-amount__cents.andes-money-amount {
    &__cents--superscript-XSMALL {
      font-size: 10px;
      font-weight: $font-weight-semibold;
      margin-top: 1px;
    }

    &__cents--superscript-SMALL {
      font-size: 10px;
      font-weight: $font-weight-semibold;
      margin-top: 1px;
    }
  }
}
