.#{$pdp-prefix}-qadb__search-bar {
  margin-top: 32px;

  &__input {
    .andes-form-control__field {
      padding-right: 48px;

      &::placeholder {
        font-size: $font-size-14;
      }
    }

    &--shows-close {
      .andes-form-control__field {
        padding-right: 74px;
      }
    }
  }

  &__input-container {
    position: relative;
    display: block;
    margin-top: $andes-spacing-16;
  }

  &__search {
    width: 48px;
  }

  &__tooltip {
    .andes-tooltip-button-close {
      right: 46px;
    }
  }

  &__clear-search {
    right: 60px;
  }

  &__auto-suggest {
    position: absolute;
    width: 100%;
    box-shadow: 0 8px 16px 0 rgba($andes-black, 0.15);
    background-color: $andes-white;
    border-radius: 6px;

    &__row {
      width: 100%;
      background-color: $andes-white;
      padding-left: 16px;
      padding-bottom: 18px;
      padding-top: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;

      &__icon {
        margin-right: 16px;
      }

      &:focus {
        outline: 0;
      }
    }

    &__row:hover {
      cursor: pointer;
      background-color: color(color_gray_lighter);
    }
  }

  &__tooltip.andes-tooltip--highlight {
    width: calc(100% - 32px);

    .andes-tooltip-arrow::before {
      top: -6px;
    }
  }
}

@media (width <= 330px) {
  .#{$pdp-prefix}-qadb__search-bar {
    &__input {
      .andes-form-control__field::placeholder {
        font-size: $font-size-12;
      }
    }
  }
}
