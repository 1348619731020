@import './generic-summary';

.#{$pdp-prefix}-generic-summary {
  padding: 0 16px;

  &__map-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 3rem;
    font-weight: 400;
    max-width: 400px;
    margin-left: 18px;
  }

  &__import-fees {
    display: flex;
    margin-left: 18px;
    padding-bottom: $andes-spacing-20;
  }

  &__bs-close-button {
    position: absolute;
    right: 0;
    top: -50px;
  }

  &__bs-close-button-native {
    position: absolute;
    right: 0;
    top: 10px;
  }

  &__message {
    margin: $andes-spacing-20 $andes-spacing-16 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .mt-4 {
    margin-top: $andes-spacing-4;
  }
}

.#{$pdp-prefix}-container__row {
  & > .andes-tooltip__trigger {
    width: 100%;
  }
}

.#{$pdp-prefix}-icon--cbt-summary {
  width: 173px;
  height: 15px;
  position: relative;
  top: 2px;
}

.#{$pdp-prefix}-generic-summary-modal {
  // using component id to add class selector
  &.shipping_summary__modal, // sass-lint:disable-line class-name-format
  &.second_shipping_summary__modal, // sass-lint:disable-line class-name-format
  &.#{$pdp-prefix}-shipping-modal {
    .andes-modal__header,
    .andes-modal__header--sticky {
      position: relative;
      background: none;
      z-index: 1;
    }

    .andes-modal__content {
      margin-top: (-$andes-spacing-32);
      position: relative;
      z-index: 0;
    }

    .andes-modal__header--sticky {
      box-shadow: none;
    }

    .andes-modal__close-button,
    .andes-modal__header--sticky .andes-modal__close-button {
      margin: 0;
      position: relative;
      top: $andes-spacing-8;
    }
  }

  &.#{$pdp-prefix}-action-modal__modal--no-title {
    .andes-modal__content {
      margin: 0;
    }
  }

  .andes-modal__header {
    padding-bottom: $andes-spacing-16;
  }

  .andes-modal__content {
    height: calc(100% - 82px);
    margin: $andes-spacing-32 $andes-spacing-32 0;
    padding: 0;
    position: relative;
  }

  .#{$pdp-prefix}-iframe {
    min-height: 100%;
    inset: 0;
    position: absolute;
  }
}

.#{$pdp-prefix}-buybox {
  .#{$pdp-prefix}-generic-summary {
    padding: 0;
  }
}

.ui-vpp-card-tooltip.shipping-summary {
  margin-top: $andes-spacing-12;
}

// Custom styles for Import Fees BottonSheet
.andes-bottom-sheet__swiper {
  visibility: hidden;
}
