@import '../../../styles/variables';
@import './counter';

.#{$pdp-prefix}-carousel-snapped {
  &__slide {
    justify-content: center;
    margin: auto;

    & .image {
      width: 100%;
    }

    & .image > img {
      padding: 16px;
      object-fit: contain;
      object-position: center;
    }

    .clip-wrapper > .clip-wrapper__thumbnail {
      padding: 0;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 24px);
    pointer-events: none;
  }

  &__overlay + &__counter {
    background-color: $andes-white;
  }

  &:has(> &__overlay) {
    ~ .#{$pdp-prefix}-bookmark__link-bookmark {
      background-color: $andes-white;
    }

    ~ .#{$pdp-prefix}-share {
      background-color: $andes-white;
    }
  }
}
