.ui-qadb__make-question-sa {
  &__label {
    text-align: center;
    margin-top: 21px;
  }

  &__button {
    width: 100%;
    margin-left: 0;
    height: 48px;
    margin-top: 18px;

    // @TODO Andes Revisar esto, tal vez la clase deberia ser .andes-button
    .andes-button__content {
      font-size: $font-size-16;
    }
  }

  &__content {
    .andes-form-control__field {
      min-height: 64px;
    }

    .andes-form-control__message-fixed {
      margin-top: 0;
    }
  }
}
