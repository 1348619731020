.ui-seller-data-recos {
  &__container {
    padding-top: $andes-spacing-16;
    margin-top: $andes-spacing-16;
    border-top: 1px solid $pdp-gray-100;

    .ui-recommendations-list__items-wrapper--double {
      margin: 0;

      .ui-recommendations-card--horizontal {
        padding: $andes-spacing-12 0;

        &:not(:last-child) {
          border-bottom: 0;
        }
      }
    }
  }
}
