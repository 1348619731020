@import './bookmark';

.#{$pdp-prefix}-bookmark {
  margin-right: 30px;

  &__icon-bookmark {
    width: 16px;
    height: 16px;

    g {
      stroke-width: 2;
    }
  }

  &__icon-bookmark-fill {
    width: 20px;
    height: 18px;

    g {
      stroke-width: 1.5;
    }
  }

  &__link-bookmark {
    height: $andes-spacing-20;
    border: 0;
    background: transparent;
    align-items: center;
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__circle-bookmark {
    background-color: $andes-gray-040-solid;
    border-radius: 50%;
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    margin: 0;

    small.ui-pdp-bookmark__label {
      margin: inherit;
    }
  }
}

.#{$vpp-prefix}-grouped-share-bookmark {
  align-items: center;

  .#{$pdp-prefix}-bookmark__icon-bookmark {
    position: relative;

    &-fill {
      position: absolute;
      left: 0;
    }
  }
}
