.#{$pdp-prefix}-compats {
  &__compats {
    padding-left: $andes-spacing-16;
    padding-right: $andes-spacing-16;

    &-custom {
      padding-top: 0;
    }
  }
}

.compats-block-sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}

.compats-block-sticky-active {
  box-shadow: 0 6px $andes-spacing-16 0 $andes-gray-100;
  background-color: $andes-white;
  padding-bottom: $andes-spacing-12;
  padding-top: $andes-spacing-16;
}
