@import '../../../styles/variables';

.container-advertising.ui-fullscreen-ad-pdp.loaded.billboard-context {
  padding: $andes-spacing-16;
  border-top: solid 1px $andes-gray-100;
  background: $andes-white;
}

.container-advertising.ui-fullscreen-ad-pdp.loaded.billboard-context.placement {
  margin-top: -24px;
  border-bottom: solid 1px $andes-gray-100;
  padding: 0 $andes-spacing-16 $andes-spacing-16;
  margin-bottom: $andes-spacing-40;
  border-top: none;
}
