@import 'seller-info';

.#{$pdp-prefix}-buybox {
  .#{$pdp-prefix}-seller {
    &__header__title {
      flex-wrap: wrap;
      margin-top: 0;
    }

    &__with-logo {
      .#{$pdp-prefix}-seller__header__title {
        font-size: $font-size-16;

        .#{$pdp-prefix}-action-modal__link {
          font-size: $font-size-16;
        }
      }
    }
  }
}

.#{$pdp-prefix}-seller {
  &__compats {
    padding-top: $andes-spacing-32;
    margin-left: $andes-spacing-16;
    margin-right: $andes-spacing-16;
  }

  &__container-info {
    flex-direction: column;
    margin-top: 0;
  }

  &__list-info {
    margin-top: 24px;
    width: 100%;
  }
}

.#{$pdp-prefix}-modal-seller {
  &.andes-modal--full .andes-modal__title {
    hyphens: auto;
  }

  .andes-modal__header {
    padding-bottom: 14px;
    user-select: none;
  }

  .andes-modal-title {
    font-size: $font-size-24;
    font-weight: $font-weight-regular;
  }
}

.#{$pdp-prefix}-s-list {
  .#{$pdp-prefix}-seller {
    .#{$pdp-prefix}-action-modal {
      font-size: 12px;

      &__link {
        font-size: 12px;
      }
    }
  }
}

.#{$pdp-prefix}-container__row--seller {
  display: block;
}
