.#{$vpp-prefix}-merch-ecosystem__vip-mobile-container {
  border-top: 1px solid $andes-gray-100;
  padding: $andes-spacing-40 $andes-spacing-16;
  display: flex;
}

.#{$vpp-prefix}-merch-ecosystem {
  &__box {
    margin: 0;
  }
}
