@import './breadcrumb';

// Configure max items showed in "compact" mode:
// This value must be the same as "minCategoriesSize" inside the component.
$min-categories-size: 5;

.#{$pdp-prefix}-breadcrumb {
  width: 100%;
  margin: 0;
  line-height: 2;

  &--compact {
    .andes-breadcrumb__item:not(:nth-child(-n + 2)):not(:nth-last-child(-n + #{$min-categories-size - 1})) {
      // This hides all the elements but the first two and de last ($min-categories-size - 1).
      // For SEO purposes we need to hide items through CSS while still rendering their tags
      // This is only for "compact" mode which is intended to be used in mobile footer

      display: none;
    }
  }
}
