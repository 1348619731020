@import './moq-information';

.#{$pdp-prefix}-moq-information {
  &__container {
    display: flex;
    align-items: center;

    &__info-icon {
      display: flex;
    }
  }
}
