@import './hs-highlighted-features-list';

.#{$vpp-prefix}-highlighted-specs__features-list {
  margin-left: 16px;
  margin-right: 16px;

  &-item {
    margin-top: 16px;
    padding-left: 15px;
    text-indent: -16px;

    &:first-child {
      margin-top: 24px;
    }

    &::before {
      color: $andes-gray-250;
      padding-right: 10px;
    }
  }
}
