.#{$pdp-prefix}-qadb__smart-answer-modal {
  &__label {
    width: 100%;
    overflow: scroll;
    max-height: 85vh;
    line-height: $font-size-22;
    color: color(color_gray_dark);
    text-align: center;

    .ui-pdp-action-modal {
      display: inline-block;
      font-size: $font-size-16;
      line-height: $font-size-22;
      margin: 0;
    }

    b {
      font-weight: $font-weight-semibold;
    }
  }

  &__button {
    width: 100%;

    &:last-child {
      margin-top: $andes-spacing-8;
    }

    .andes-button__content {
      font-weight: $font-weight-semibold;
    }
  }

  .andes-modal__actions {
    padding: 0 $andes-spacing-32 $andes-spacing-32;

    // sass-lint:disable nesting-depth //
    .andes-button--filled {
      display: block;
    }
  }

  .andes-modal__header {
    padding-bottom: 0;
  }

  .andes-modal__close-button {
    margin-top: 0;
  }

  .andes-modal__content {
    padding: $andes-spacing-32 $andes-spacing-32 $andes-spacing-24;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .andes-modal__actions {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &__modal {
    margin: 0 $andes-spacing-32;

    .andes-modal__content,
    &.andes-modal--card.andes-modal--fixed-actions .andes-modal__content {
      padding: 0 $andes-spacing-32 $andes-spacing-32;
    }

    .andes-modal__actions {
      margin-top: $andes-spacing-32;
    }
  }
}

.ui-pdp-qadb__smart-answer-modal__button:last-child {
  line-height: 1;
}
