@import '~@andes/carousel-free/index';
@import 'coupons';
@import 'coupons-carousel-pills';

.ui-vpp-coupons {
  margin-bottom: $andes-spacing-24;
  margin-left: -$andes-spacing-16;
  margin-right: -$andes-spacing-16;

  &__title {
    margin-left: $andes-spacing-16;
  }

  &__carousel {
    margin-left: $andes-spacing-16;
    margin-right: $andes-spacing-16;
  }

  &__text {
    margin-left: $andes-spacing-16;
  }

  &__action.ui-pdp-media {
    align-items: flex-end;
    height: 18px;
    margin-top: $andes-spacing-8;

    .ui-pdp-media__body {
      margin-left: $andes-spacing-16;
    }
  }

  &__awareness &__pills-container {
    margin-top: 0;
  }

  &__awareness &__text {
    margin-top: 0;
  }

  .andes-button {
    width: max-content;
  }

  .andes-carousel-free {
    .andes-carousel-free__list--spacing-0 {
      .andes-carousel-free__slide:not(:last-child) {
        margin-right: $andes-spacing-8;
      }
    }
  }
}

// Coupon Modal
.vpp-coupons-modal__modal {
  .andes-modal__header {
    padding-bottom: $andes-spacing-12;
  }

  .#{$pdp-prefix}-iframe {
    height: 100%;
  }

  .andes-modal__content {
    padding: 0;
  }
}
