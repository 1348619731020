.#{$pdp-prefix}-share {
  position: relative;

  &__link {
    position: relative;
    display: flex;
    align-items: center;

    &__label {
      color: $andes-blue-500;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      line-height: $line-height-xs;
      margin-left: $andes-spacing-8;
    }
  }

  &__input {
    display: none;
  }

  &__tooltip {
    display: none;
    padding: $andes-spacing-8;
    background: $andes-white;
    border-radius: $andes-spacing-4;
    box-shadow: 0 1px 3px $andes-gray-250;
    border: 1px solid $andes-bg-primary;
  }
}

.ui-pdp-share__input--gallery:checked + .ui-pdp-share__tooltip--gallery {
  display: block;
}

.ui-pdp-share__input--body:checked + .ui-pdp-share__tooltip--body {
  display: block;
}

.ui-pdp-share__tooltip--body {
  position: absolute;
  top: 32px;
  right: 0;
}
