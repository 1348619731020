@import '../components/make-question/styles/make-question.mobile';
@import '../components/make-question-sa/styles/make-question.mobile';
@import '../components/card-answer/styles/card-answer.mobile';
@import '../components/question-form/styles/question-form.mobile';
@import '../components/questions-list/styles/questions-list.mobile';
@import '../components/no-last-questions/styles/no-last-questions.mobile';
@import '../components/question-modal/styles/question-modal.mobile';
@import '../components/search-bar/styles/search-bar.mobile';
@import '../components/feedback/styles/feedback.mobile';
@import '../components/questions-quick-access/styles/questions-quick-access.mobile';
@import '../components/find-in-questions/styles/find-in-questions.mobile';
@import '../components/how-to-make-question-button/styles/how-to-make-question-button.mobile';
@import '../components/smart-answer/styles/smart-answer.mobile';
@import '../components/smart-answer-modal/styles/smart-answer-modal.mobile';

.#{$pdp-prefix}-qadb {
  scroll-behavior: smooth;
  border-top: none;
  padding-top: 0;

  &__title {
    font-size: 20px;
  }
}
