@import './questions';
@import './questions.stock-modal';
@import './questions.stock-modal.mobile';
@import './questions.denounce-modal';

.#{$pdp-prefix}-questions {
  width: 100%;

  &__checkbox-credits-interest__wrapper {
    display: flex;
    margin-bottom: $andes-spacing-24;

    .andes-checkbox__label {
      line-height: $line-height-s;
      display: inline-block;
    }
  }

  &__title {
    font-size: $font-size-20;
    font-weight: $font-weight-regular;
    margin-bottom: 24px;
  }

  &__title,
  &__subtitle,
  &__subtitle--question {
    color: color(color_gray_dark);
  }

  &__subtitle,
  &__subtitle--question {
    font-size: $font-size-18;
    font-weight: 600;
  }

  &__subtitle {
    padding-bottom: 18px;
  }

  &__subtitle--question {
    margin-top: 32px;
  }

  &__content-input {
    display: inline-block;
  }

  &__input {
    font-size: $font-size-15;
    width: 100%;
    height: auto;
    margin-bottom: 0;

    .andes-form-control__control {
      .andes-form-control__field {
        text-indent: 0;
        padding-left: $andes-spacing-16;
        padding-right: $andes-spacing-16;
        height: fit-content !important;
        min-height: 48px !important;
      }
    }

    & .andes-form-control__field::placeholder {
      font-size: $font-size-15;
    }
  }

  &__button {
    display: inline-block;
    padding-left: 35px;
    font-size: $font-size-16;
    width: 100%;
    margin-bottom: 24px;
  }

  &__time-text {
    display: block;
    margin-top: 3px;
    font-size: $font-size-14;
    color: color(color_gray_middle);
  }

  &__item {
    display: flex;
  }

  &__list {
    margin: (-$andes-spacing-8) 0 0 (-$andes-spacing-8);

    &::-webkit-scrollbar {
      display: none;
    }

    .seo-ui-anchor__button {
      padding: 10px $andes-spacing-12;
      height: 34px;
      width: auto;
    }
  }

  &__link {
    display: inline-flex;
    height: 34px;
    padding: 10px 12px;
    font-size: $font-size-12;
    font-weight: $font-weight-semibold;
    margin: $andes-spacing-8 0 0 $andes-spacing-8;
    line-height: 1.17;
    align-items: center;
    vertical-align: middle;
    white-space: initial;
    text-align: left;
  }

  &__message-container {
    margin-top: 16px;
    padding: 16px;
    border-radius: 4px;
  }

  &__stock-modal {
    &__button {
      width: 100%;
    }
  }

  &__message-location {
    &--simple {
      .#{$pdp-prefix}-icon,
      figure {
        width: 12px;
        height: 16px;
      }

      .#{$pdp-prefix}-media__title {
        &.#{$pdp-prefix}-size--XXSMALL {
          font-size: $font-size-12;
        }
        &.#{$pdp-prefix}-size--XSMALL {
          font-size: $font-size-14;
        }
        &.#{$pdp-prefix}-size--SMALL {
          font-size: $font-size-16;
        }
      }
    }
  }

  &__additional-actions {
    padding: $andes-spacing-8 0 0;
  }

  &__questions-list {
    &__item-questions--others-questions:first-child {
      padding-top: 0;
    }

    &__item-questions--others-questions .#{$pdp-prefix}-questions__questions-list__question {
      margin-right: $andes-spacing-8;
    }
  }

  .#{$pdp-prefix}-recaptcha-v2 {
    margin-top: $andes-spacing-24;
  }

  &__questions-list__question-item__denounce {
    &__overriding-default-button {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      margin-left: 0;
      padding: 0;
      height: $andes-spacing-20;
      line-height: $andes-spacing-20;
    }
  }

  .#{$pdp-prefix}-questions__form {
    margin-top: $andes-spacing-16;

    .#{$pdp-prefix}-questions__button {
      height: $andes-spacing-48;
      margin-left: 0;
      margin-top: 18px;
      width: 100%;
    }
  }
}

.ui-pdp-action-modal__buy-multiple-variations {
  .andes-modal__scroll {
    overflow-y: hidden;
  }
}
