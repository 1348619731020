.#{$vpp-prefix}-highlighted-specs__highlighted-attributes-list {
  width: 100%;

  &__attribute {
    width: 100%;

    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }
}
