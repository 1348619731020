@import './stock-information';

.#{$pdp-prefix}-stock-information {
  line-height: 1;

  &__container {
    display: flex;
    align-items: center;

    &:not(:empty) {
      margin-top: $andes-spacing-4;
    }

    &__info-icon {
      display: flex;
    }
  }
}
