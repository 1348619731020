.ui-vip-core.ui-vip-core-mshops,
.ui-pdp.ui-pdp-mshops {
  &-container--explorer,
  &-container--legend,
  &-container--simple {
    .ui-recommendations-carousel-free,
    .ui-recommendations-list {
      &__header {
        margin-bottom: $andes-spacing-16;

        > .ui-recommendations-title > .ui-recommendations-title-link,
        &-titles > .ui-recommendations-title > .ui-recommendations-title-link {
          font-weight: $font-weight-bold;
        }
      }

      .ui-recommendations-card__content {
        .ui-recommendations-card__title {
          color: $andes-text-color-secondary;
          font-size: $font-size-14;
          margin-bottom: $andes-spacing-8;
        }

        .ui-recommendations-card__price-block {
          margin-bottom: $andes-spacing-8;

          .ui-recommendations-card__price-top {
            margin-bottom: $andes-spacing-8;

            .ui-recommendations-card__price-and-discount .ui-recommendations-card__price {
              font-size: $font-size-16 !important;
              font-weight: $font-weight-bold;
            }
          }

          .ui-recommendations-card__installments {
            .ui-recommendations-card__installments-price--free,
            .andes-money-amount {
              color: $andes-text-color-secondary;
              font-size: $font-size-12 !important;
            }
          }
        }
      }
    }
  }
}
