.#{$rating-prefix} {
  padding: $andes-spacing-24;

  &__title {
    margin-bottom: $andes-spacing-20;
  }

  &__level {
    grid-column-gap: 12px;

    &:not(:last-child) {
      margin-bottom: $andes-spacing-8;
    }
  }
}
