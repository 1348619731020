@import './installation-widget';

.ui-pdp-container__row--installations {
  padding: $andes-spacing-16;
}

.ui-pdp-installation-modal {
  display: block;

  .andes-modal__header {
    display: none;
    padding: 15px 1.5em;
  }

  .andes-modal__close-button path {
    stroke: $andes-gray-900;
  }

  .andes-modal__content {
    padding: 0;
    overflow: hidden;
  }

  .andes-modal__scroll {
    border-radius: 0;
  }

  .ui-pdp-iframe {
    height: calc(100% - 48px);
  }
}

.ui-pdp-installation__navbar {
  height: 48px;
  display: flex;
  width: 100%;
  font-size: 16px;
  background-color: $andes-yellow-500;
  align-items: center;
  position: relative;

  &-icon {
    position: absolute;
  }

  &-icon.andes-button--transparent path {
    stroke: $andes-black;
  }

  &-icon.andes-button--transparent:active {
    background-color: transparent;
  }

  &-title {
    margin: 0 auto;
  }
}

.cx-widget:focus:not(:focus-visible).cx-widget--visible {
  width: 100%;
}
