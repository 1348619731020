.#{$pdp-prefix}-share__content {
  &__title {
    color: color(color_gray_dark);
    font-size: $font-size-18;
    font-weight: $font-weight-semibold;
    padding: $andes-spacing-16;
    padding-bottom: 20px;
  }

  &__list {
    display: flex;
    padding: 24px 26px;
    padding-top: unset;
    justify-content: space-between;
    align-items: center;

    &__item {
      align-items: center;
      display: flex;
      flex-direction: column;

      &__link {
        display: flex;
        height: 36px;
        align-items: center;
        margin-bottom: $andes-spacing-8;
      }

      &__label {
        color: color(color_gray_dark);
        font-size: $font-size-12;
        font-weight: $font-weight-regular;
        text-align: center;
      }
    }

    &__item:not(:last-of-type) {
      margin-right: 20px;
    }

    &--just-icons {
      padding: 0;
    }
  }
}
