.#{$vpp-prefix}-highlighted-specs__see-more {
  $self: &;

  .ui-pdp-see-more__action {
    margin-top: 0;
  }

  .ui-pdp-see-more--cant-see-everything {
    position: relative;
    overflow: hidden;
    margin-top: -1px;
    padding-top: 1px;
    margin-bottom: 32px;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      background-image: linear-gradient(
        to bottom,
        color(color_black_1000),
        color(color_gray_500) 85%,
        $andes-white 100%
      );
    }
  }
}
