@import '../../../styles/variables';

.#{$pdp-prefix} .#{$pdp-prefix}-container__row--cpg-summary {
  .#{$pdp-prefix}-generic-summary {
    padding: 0;
  }
}

.#{$pdp-prefix}-container__row--cpg-summary {
  .#{$pdp-prefix}-color--BLACK.#{$pdp-prefix}-media__text {
    margin-top: 2px;
  }

  .#{$pdp-prefix}-color--GRAY.#{$pdp-prefix}-media__text + .#{$pdp-prefix}-color--BLACK.#{$pdp-prefix}-media__text {
    margin-top: 8px;
  }
}
