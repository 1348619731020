.#{$pdp-prefix}-qadb__make-question {
  &__label {
    text-align: center;
    margin-top: 21px;
  }

  &__button {
    width: 100%;
    margin-top: 17px;
    height: 48px;

    // @TODO Andes Revisar esto, tal vez la clase deberia ser .andes-button
    .andes-button__content {
      font-size: $font-size-16;
    }
  }
}

.andes-modal__overlay--card {
  .#{$pdp-prefix}-qadb__make-question__modal {
    width: 100%;
    margin: 0 $andes-spacing-24;
    min-height: 272px;
    max-height: 272px;

    .andes-modal {
      &__header {
        padding: $andes-spacing-20 $andes-spacing-24;
      }

      &__title {
        font-size: 1.45em;
        line-height: 1.3;
      }
    }

    .andes-form-control--textfield.andes-form-control--multiline .andes-form-control__field {
      min-height: 115px;
      max-height: 115px;
      color: color(color_gray_dark);
      line-height: 1.29;
      padding: $andes-spacing-12;
    }

    .andes-form-control--textfield.andes-form-control--multiline .andes-form-control__field,
    .andes-form-control--textfield.andes-form-control--multiline .andes-form-control__field::placeholder {
      font-size: $font-size-14;
    }

    // sass-lint:disable bem-depth //
    .ui-pdp-qadb__question-form__form__actions {
      margin-bottom: 0;
      margin-top: 21px;
      text-align: center;
    }
  }

  @media (width <= 320px) {
    .andes-modal-title {
      font-size: 1.07em;
    }

    .ui-pdp-question-modal__subtitle {
      font-size: $font-size-14;
    }
  }
}
