@import '../../../styles/variables';

.#{$pdp-prefix}-questions__denounce-modal {
  &__list {
    color: color(color_gray_dark);
  }

  &__item {
    border-top: 1px solid $border-grey;
    height: 50px;
    text-align: center;

    &__link {
      width: 100%;
      height: 100%;
      display: inline-block;
      padding: 14px;
      font-size: $font-size-16;
      color: color(color_gray_dark);
      border: none;
      background-color: transparent;
      padding-top: 12px;
      font-family: $font-family-primary;

      &:focus,
      &:visited {
        outline: none;
        color: color(color_gray_dark);
      }
    }
  }

  &__item:first-child {
    border-top: none;
  }
}
