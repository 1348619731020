@import '../../../styles/variables';
@import './lightbox';

$pdp-pswdp-color-start: color(color_black_20);
$pdp-pswdp-color-end: color(color_black_01);

.pswp.inline {
  top: auto;
  position: relative;
}

.pswp__top-bar {
  background-image: linear-gradient(to bottom, $pdp-pswdp-color-start, $pdp-pswdp-color-end);
  content: '';
  display: block;
  height: 60px;
  padding-top: 1.5rem;
  width: 100%;
  pointer-events: none;
}

.pswp__counter {
  color: $andes-white;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  left: auto;
  opacity: 1;
  padding-top: 1.5rem;
  right: 1rem;
  top: 0;
}

// sass-lint:disable max-line-length
.pswp__button--close {
  background-color: inherit;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAyMiAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNTM0NCAwLjIwNDU5TDEwLjU5MyAxLjI2NzM0TDMuMDU2MjMgOC43NzQzM0gyMS40ODU2VjEwLjI3NDNIMy4wNTYyM0wxMC41OTMgMTcuNzgxM0w5LjUzNDQgMTguODQ0MUwwLjE3NzczNCA5LjUyNDMzTDkuNTM0NCAwLjIwNDU5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==') !important;
  background-position: center center;
  background-size: 21px 22px;
  fill: $andes-white;
  float: left;
  margin-left: 0.5rem;
  pointer-events: all;
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
  display: none;
}

.pswp > .dots-navigation {
  bottom: 1.5rem;
  position: absolute;
}
