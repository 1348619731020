@import '../../../styles/mixins';
@import '../../lightbox/styles/lightbox.mobile';
@import '../../carousel-snapped/styles/carousel-snapped';

$horizontal-item-height: 368px;

.#{$pdp-prefix}-gallery {
  height: auto;
  width: 100%;
  overflow: hidden;
  position: relative;

  &-with-logo {
    overflow: unset;
  }

  .#{$pdp-prefix}-share {
    position: absolute;
    z-index: 10;
    right: $andes-spacing-16;
    transform: translateY(-74px);
    box-sizing: border-box;
    border-radius: $andes-spacing-40;
    background-color: $andes-gray-040-solid;
  }

  .#{$pdp-prefix}-bookmark__circle-bookmark {
    position: absolute;
    right: 1rem;
    top: 12px;
    z-index: 10;
  }

  .#{$pdp-prefix}-share__tooltip--gallery {
    position: absolute;
    top: 60px;
    right: 0;
  }

  &__clip.clip {
    width: 45vw;
    height: 80vw;
  }

  &--horizontal {
    height: 80vw;
  }

  &--square {
    height: 100vw;
  }

  &--vertical {
    height: 120vw;
  }

  &--default {
    height: auto;
    min-height: 368px;
    max-height: 500px;
  }

  &__logo {
    max-width: 64px;
    max-height: 64px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 0 !important;
  }

  &__logo-container {
    position: absolute;
    z-index: 2;
    left: $andes-spacing-8;
    bottom: -$andes-spacing-8;
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 $pdp-gray-100;
    width: 88px;
    height: 64px;
    background-color: $andes-white;
  }

  &-bigger-photo {
    .#{$pdp-prefix}-share {
      right: 20px;
      bottom: 8px;
      transform: none;
    }

    .#{$pdp-prefix}-carousel-snapped__counter {
      left: 20px;
      top: 8px;
    }

    .#{$pdp-prefix}-image {
      padding: 0 !important;
    }
  }

  @media screen and (orientation: landscape) {
    &__clip {
      width: 15rem !important;
      height: $horizontal-item-height !important;
    }
  }

  @media screen and (orientation: landscape) {
    &__clip {
      width: 15rem !important;
      height: 88vh;
    }
  }

  @media screen and (orientation: landscape) {
    &--horizontal,
    &--square {
      height: $horizontal-item-height;
    }
  }
}
