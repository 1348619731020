@import './on-demand-iframe';

.on-demand-iframe--mobile {
  .andes-modal__header {
    display: none;
  }

  .andes-modal__close-button {
    right: $andes-spacing-16;
  }

  &.on-demand-iframe--mobile__container {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    min-height: 70px;
    border-radius: 10px 10px 0 0;

    iframe {
      height: 0;
      transition: height 0.3s;
    }
  }

  &.on-demand-iframe--mobile__fullscreen {
    height: 100vh;

    iframe {
      height: calc(100vh - 5px);
    }
  }
}

.on-demand-iframe--questions-ai {
  min-height: 266px;

  iframe {
    transition: none;
  }
}

.on-demand-iframe--split-payments {
  .andes-modal__content {
    max-height: 60vh;
    padding: $andes-spacing-20;
  }
}
