@import './card-tooltip';

.#{$pdp-prefix}-card-tooltip {
  font-size: $font-size-14;
  margin-top: $andes-spacing-12;
  padding: 0 $andes-spacing-16;
}

.#{$vip-prefix}-card-tooltip {
  padding: 0;
  margin-top: 0;
  margin-inline: $andes-spacing-16;
}
