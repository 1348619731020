.#{$vpp-prefix}-highlighted-specs__striped-specs {
  .ui-vpp-striped-specs {
    &__table {
      &:not(:first-of-type) {
        margin-top: 24px;
      }

      &:last-of-type {
        margin-bottom: 24px;
      }
    }
  }
}
