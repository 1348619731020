.ui-pdp-reputation__seller {
  padding: 0 $andes-spacing-16;
  margin: $andes-spacing-24 0 0;

  .ui-pdp-reputation-seller {
    .ui-pdp-reputation-title-link {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ui-pdp-reputation-main-info-seller {
    max-width: 100%;
    font-size: $font-size-14;
  }

  .ui-pdp-reputation-main-info-seller-logo {
    max-width: 84%;
  }

  .ui-pdp-reputation-seller-info {
    .andes-thumbnail-container {
      display: flex;
      align-items: flex-start;
    }
  }
}
@media (width <= 360px) {
  .ui-pdp-reputation__seller {
    .ui-pdp-reputation-seller {
      display: block;

      .ui-pdp-reputation-title-link {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ui-pdp-reputation-ventas::before {
      content: '';
    }

    .ui-pdp-reputation-mercado-lider {
      margin-right: 16px;
    }
  }
}
