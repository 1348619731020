$rs-gray-450: rgba($andes-black, 0.45) !default;

.#{$pdp-prefix}-related-search {
  &__title {
    color: $rs-gray-450;
    font-size: $font-size-14;
    margin: 3px 0 $andes-spacing-16 $andes-spacing-16;

    &::after {
      content: ':';
      display: inline-block;
    }
  }

  &__list {
    background-color: $andes-white;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: 1;
    margin: 0 $andes-spacing-16;
    padding: 0;
  }

  &__item {
    border-bottom: 1px solid $andes-bg-primary;
    color: $rs-gray-450;

    .ui-pdp-icon {
      height: 13px;
      width: 13px;
      margin-right: 14px;
      margin-top: 1px;
    }
  }

  &__link {
    display: flex;
    color: $andes-gray-550;
    padding: 19px 0 19px $andes-spacing-16;
    width: 100%;
  }

  &__link-column {
    display: inline-flex;
  }

  &__link-icon {
    flex-shrink: 0;
  }

  &__link-text {
    flex-grow: 1;
  }
}
