.#{$pdp-prefix}-buybox__quantity {
  &__input {
    &-form {
      padding: 0 $andes-spacing-16 $andes-spacing-16;
    }
  }

  &__stock {
    font-size: $font-size-14;
    padding: $andes-spacing-4 $andes-spacing-16 $andes-spacing-16;

    &__yield {
      color: $andes-text-color-secondary;
      padding: $andes-spacing-8 $andes-spacing-16 $andes-spacing-16;
      line-height: 1;
    }

    &__custom-options {
      display: block;
      color: $andes-text-color-secondary;
      font-weight: $font-weight-regular;
      font-size: $font-size-14;
      border-bottom: 1px solid $andes-gray-150-solid;
      margin-bottom: $andes-spacing-24;
    }
  }

  &__button {
    margin-top: 5px;
    width: 100%;
  }

  &__button-bulk {
    margin-top: $andes-spacing-16;
    width: 100%;
  }

  &__button-custom-options,
  &__button-bulk-custom-options {
    margin-top: $andes-spacing-16;
  }

  &.andes-form-control__message {
    font-size: $font-size-12;
  }

  &__disclaimer {
    margin-top: 8px;
  }
}
