@import '../../../styles/variables';

.#{$pdp-prefix}-carousel-snapped {
  &__counter {
    background-color: $andes-bg-secondary;
    border-radius: 12px;
    color: color(color_gray_dark);
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    height: 22px;
    left: 15px;
    padding: 2px 0;
    position: absolute;
    top: 12px;
    width: 40px;
    z-index: $z-index - 20; // @TODO: Until search adds the z-index from andes
    text-align: center;
  }
}
