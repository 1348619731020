@import '../../../styles/variables';

$carousel-box-shadow-logo: rgb(0 0 0 / 10%);

.navigation-official-stores {
  padding: $layout-padding-40 0;
  border-top: 1px solid $pdp-gray-100;

  .andes-carousel-snapped__wrapper {
    height: 215px;
  }

  .andes-carousel-snapped__slide {
    width: 260px;
  }

  .andes-card {
    width: 260px;
    height: 210px;
    box-shadow: 0 7px 16px 0 $pdp-gray-100, 0 1px 3px 0 $pdp-gray-100;
  }

  .andes-carousel-free {
    padding-left: $andes-spacing-16;

    &__list {
      height: 232px;
    }

    &__slide:last-child {
      margin-right: $andes-spacing-16;
    }
  }

  &__logo {
    border-radius: 6px;
    box-shadow: 0 2px 15px 0 $carousel-box-shadow-logo;
    margin: 0 $andes-spacing-16;
    overflow: hidden;
    height: 80px;
    width: 80px;
    position: relative;
  }

  &__header {
    display: flex;
    align-items: baseline;
    margin-bottom: $andes-spacing-24;
    margin-left: $andes-spacing-16;
  }

  &__view-more {
    margin-left: $andes-spacing-16;
  }

  &__description {
    margin: $andes-spacing-12 0 0 $andes-spacing-16;

    &__title {
      display: flex;
      align-items: center;

      .ui-pdp-cockade-icon {
        margin-left: $andes-spacing-4;
      }
    }
  }

  &__background {
    margin-bottom: -59px;
    height: 107px;
    width: 260px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    img {
      border-radius: 6px 6px 0 0;
      object-fit: cover;
      height: 100%;
    }
  }

  &__divider {
    background: $pdp-gray-100;
    height: 1px;
    position: relative;
    top: 59px;
  }

  &__container-action {
    width: calc(100% - 30px);
    height: 48px;
    border: 1px solid $pdp-gray-100;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
    margin-left: $andes-spacing-16;

    .ui-pdp-icon--chevron {
      margin-right: $andes-spacing-12;
    }
  }
}
