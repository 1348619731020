.#{$pdp-prefix-reviews}__comments {
  &__tab-content {
    padding: 0 $andes-spacing-16;
  }

  &__button {
    font-weight: $font-weight-regular;
    margin: 0 auto;
    margin-top: $andes-spacing-24;
  }
}
