@import '../quantity/styles/quantity.mobile';
@import '../shipping/styles/shipping';

.#{$pdp-prefix}-buybox {
  width: 100%;
}

.ui-pdp-action-modal__modal.ui-pdp-iframe-modal.ui-pdp-fc-us-taxes {
  width: 568px;
  height: 386px;

  .andes-modal__header {
    padding: $andes-spacing-32 $andes-spacing-32 0;
  }

  .andes-modal__scroll {
    overflow: hidden;
    border-radius: $border-radius-6;
  }
}
