.#{$pdp-prefix}-questions {
  &__stock-modal {
    margin: 0 $andes-spacing-32;

    &.andes-modal--card .andes-modal__title {
      font-size: $font-size-24;
    }

    .andes-modal__header {
      padding: 1.5em;
    }

    .andes-modal__content {
      text-align: center;
    }
  }
}
