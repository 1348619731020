@import '../components/hs-highlighted-attributes/styles/hs-highlighted-attributes.mobile';
@import '../components/hs-highlighted-attributes-list/styles/hs-highlighted-attributes-list.mobile';
@import '../components/hs-highlighted-features-list/styles/hs-highlighted-features-list.mobile';
@import '../components/hs-discrete-bar/styles/hs-discrete-bar';
@import '../components/hs-continous-bar/styles/hs-continous-bar';
@import '../components/hs-key-value/styles/hs-key-value';
@import '../components/hs-key-value-carousel/styles/hs-key-value-carousel';
@import '../components/hs-filled-icon/styles/hs-filled-icon';
@import '../components/hs-action-row/styles/hs-action-row.mobile';
@import '../components/hs-striped-specs/styles/hs-striped.mobile';
@import '../components/hs-additional-information/styles/styles.mobile';

.#{$vpp-prefix}-highlighted-specs-rex {
  padding-bottom: 31px;
  border-top: 1px solid rgb(0 0 0 / 10%);
  padding-top: $andes-spacing-32;
  div.#{$pdp-prefix}-container__row--technical-specifications {
    margin-top: $andes-spacing-24;
  }

  .#{$pdp-prefix}-action-row--secondary {
    border: none;
    padding: 0;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;

    svg {
      position: static;
      margin-left: 10px;
      margin-top: 2px;
    }
  }
}

.#{$vpp-prefix}-highlighted-specs {
  max-width: 100%;
  width: 100%;

  .#{$pdp-prefix}-container__row {
    &--highlighted-specs-title,
    &--highlighted-features-title {
      margin-left: 16px;
      margin-right: 16px;
    }

    &--highlighted-attributes {
      margin-top: 32px;
      margin-left: 16px;
    }

    &--highlighted-attributes-list {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 32px;
    }

    &--continuous-bar {
      margin-top: 24px;
      margin-left: 16px;
      margin-right: 16px;

      &:first-of-type {
        margin-top: 32px;
      }
    }

    &--discrete-bar {
      margin-top: 32px;
      margin-left: 16px;
      margin-right: 16px;
    }

    &--key-value {
      margin-top: 24px;
      margin-left: 16px;
      margin-right: 16px;
    }

    &--technical-specifications {
      margin-top: 32px;
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  &__key-value__labels,
  &__key-value__labels__key-value {
    overflow: hidden;
  }

  &__key-value__labels__key-value {
    span {
      overflow-wrap: anywhere;
    }
  }
}
