@import './quantity-input.mobile';
@import './quantity-input-custom-label';
@import './quantity-selector.mobile';
@import './quantity-native';

.#{$pdp-prefix}-buybox__quantity {
  &__title {
    line-height: 1;
    color: color(color_gray_dark);
    font-weight: $font-weight-semibold;
    font-size: $font-size-18;
    padding: $andes-spacing-16 $andes-spacing-16 0;
    vertical-align: middle;

    &--last {
      padding: 0;
    }
  }

  &__selected {
    margin-left: 4px;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    color: color(color_gray_dark);
    white-space: nowrap;

    &__label {
      margin-left: $andes-spacing-4;
    }
  }

  &__subtitle {
    display: block;
    margin-bottom: $andes-spacing-16;
    color: $andes-text-color-secondary;
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    padding: $andes-spacing-4 $andes-spacing-16 0;
  }

  .ui-pdp-action-row--error {
    margin-bottom: 0;
  }

  &__alert {
    color: color(color_red);
    font-weight: $font-weight-semibold;
    font-size: $font-size-14;
    margin-top: $andes-spacing-4;
    display: block;

    & span {
      display: flex;
      align-items: center;

      & svg {
        width: 14px;
        height: 14px;
        margin-right: $andes-spacing-4;
        margin-left: 6px;
        border-radius: 100%;
      }
    }
  }

  &__messages {
    margin-top: 8px;
    line-height: 1;

    &__message {
      margin: 4px 0 0;
      display: inline-flex;

      [data-testid='price-part'] {
        margin: 0 0.27em;
      }

      &:empty {
        display: none;
      }
    }

    &:empty {
      display: none;
    }
  }

  .ui-pdp-price__part {
    line-height: inherit;

    .price-tag-cents {
      line-height: inherit;
      padding-top: 1px;
    }
  }
}
