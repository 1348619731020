@import './trade-in';

.trade-in__card {
  margin: $andes-spacing-20 $andes-spacing-16 0;

  &--content {
    .ui-pdp-action-modal .button {
      height: $andes-spacing-32;
      width: 69px;

      .andes-button__content {
        font-size: $font-size-14;
      }
    }
  }
}

.ui-pdp-remove-trade-in {
  &__container {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-bottom: $andes-spacing-20;
  }

  &__title {
    margin-bottom: $andes-spacing-8;
  }

  &__actions {
    flex-direction: column;
  }

  .andes-modal__scroll {
    padding: $andes-spacing-20;

    .remove-trade-in-icon {
      height: $andes-spacing-64;
      width: $andes-spacing-64;
      margin-bottom: $andes-spacing-16;

      .ui-pdp-media__figure {
        height: 100%;
        margin: 0;
      }
    }
  }
}

.ui-pdp-trade-in-modal__bottom-sheet {
  .andes-bottom-sheet__scroll {
    border-radius: $border-radius-6 $border-radius-6 0 0;
    overflow: hidden;
  }

  .andes-bottom-sheet__header {
    display: none;
  }

  .andes-bottom-sheet__content {
    height: 100%;
    padding: 0;
  }

  .andes-bottom-sheet__action {
    padding: 0 $andes-spacing-24 $andes-spacing-24;
  }

  .ui-pdp-trade-in-modal__actions {
    .ui-pdp-action-modal {
      width: 100%;

      .andes-button {
        width: 100%;
      }
    }
  }

  .ui-pdp-action-modal {
    display: flex;

    a {
      width: 100%;
    }
  }
}

.andes-progress-indicator-circular.andes-progress-indicator-circular--fullscreen {
  border-radius: $border-radius-6 $border-radius-6 0 0;
}
