.#{$compats-prefix}-highlight-card {
  margin-top: $andes-spacing-8;

  .#{$compats-prefix}-highlight-card__title {
    margin-top: 0;
  }

  .#{$compats-prefix}-highlight__text {
    max-width: calc(100% - 20px);
  }
}
