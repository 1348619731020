@import './hs-highlighted-attributes';

.#{$vpp-prefix}-highlighted-specs__highlighted-attributes {
  &--HORIZONTAL {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 12px 12px 12px 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__card {
    &--HORIZONTAL {
      flex: 0 0 auto;
    }
  }

  &__card-content {
    padding-left: 12px;
    padding-right: 16px;
  }
}
