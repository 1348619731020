.#{$pdp-prefix}-legal-tooltip {
  margin-top: 16px;
  padding-right: 34px;

  &__content {
    padding: 5px 0;
  }

  &__tooltip__title {
    display: block;
    margin-bottom: 4px;
  }

  &::before {
    left: 22px;
  }
}
