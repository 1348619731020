@import './compats-widget-sticky';

$compats-gray: color(color_gray_600);

.#{$pdp-prefix}-container__row--compats-widget-sticky {
  z-index: $z-index;

  &.ui-pdp-compats--intersecting {
    .ui-compats-header {
      box-shadow: 0 6px 16px $compats-gray;
    }
  }

  &.ui-pdp-compats--relative {
    .ui-compats-header {
      border-bottom: 1px solid $andes-gray-100;
      border-top: 1px solid $andes-gray-100;
    }
  }
}

.#{$pdp-prefix}-container__row--compats-widget-sticky-mshop {
  z-index: 500;

  &.ui-pdp-compats--intersecting {
    z-index: 1000;

    .ui-compats-header {
      box-shadow: 0 6px 16px $compats-gray;
    }
  }

  &.ui-pdp-compats--relative {
    .ui-compats-header {
      border-bottom: 1px solid $andes-gray-100;
      border-top: 1px solid $andes-gray-100;
    }
  }
}
