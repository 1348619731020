@import '../../../styles/variables';

.#{$pdp-prefix}-backdrop-modal {
  bottom: 0;
  height: auto;
  left: auto;
  overflow: visible;
  position: absolute;
  top: auto;
  transform: translate(0);
  width: 100%;

  & .andes-modal__close-button::before,
  & .andes-modal__close-button::after {
    background-color: $andes-white;
    opacity: 0.8;
  }

  &.andes-modal.andes-modal--card {
    border-radius: 10px 10px 0 0;
    font-size: $font-size-16;
  }

  &.andes-modal {
    animation: translateInBackdrop 0.2s ease-in-out, fadeIn 0.2s ease-in-out;
  }

  &.andes-modal--closing {
    animation: translateOutBackdrop 0.2s ease-in-out, fadeOut 0.2s ease-in-out;
  }

  & .andes-modal__content {
    padding: 0;
  }

  & .andes-modal__close-button {
    left: unset;
    right: $andes-spacing-16;
    top: -30px;
  }

  &.backdrop-modal__custom-options {
    & .andes-modal__close-button {
      top: $andes-spacing-16;

      svg {
        fill: $andes-text-color-secondary;

        path {
          fill: $andes-text-color-secondary;
        }
      }
    }
  }
}

.andes-modal__overlay--card {
  padding: 0;
}

@keyframes translateInBackdrop {
  from {
    transform: translate(0, calc(100% + 20px));
  }

  to {
    transform: translate(0, auto);
  }
}

@keyframes translateOutBackdrop {
  from {
    transform: translate(0, auto);
  }

  to {
    transform: translate(0, calc(100% + 20px));
  }
}
