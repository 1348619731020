.#{ui-pdp}-specs-mobile__compats {
  margin-bottom: $andes-spacing-24;
}

.#{ui-pdp}-specs-mobile__compats-disclaimer {
  margin-left: $andes-spacing-4;
  margin-right: $andes-spacing-4;
}

/* stylelint-disable-next-line no-duplicate-selectors */
.#{ui-pdp}-specs-mobile__compats {
  .ui-compats-header--dark .ui-compats-header__container {
    border-radius: $border-radius-6 $border-radius-6 0 0;
  }
}

.#{ui-pdp}-specs-mobile__compats-disclaimer,
.#{ui-vpp}-highlighted-specs .#{ui-pdp}-container__row--compats-extra-info {
  color: $andes-text-color-secondary;
  font-weight: $font-weight-regular;
  font-size: $font-size-12;
  margin-top: $andes-spacing-8;
}

.#{ui-vpp}-highlighted-specs {
  .#{ui-pdp}-container__row--compats-widget {
    margin: $andes-spacing-24 $andes-spacing-16 0;
  }

  .#{ui-pdp}-container__row--compats-extra-info {
    margin-left: $andes-spacing-20;
    margin-right: $andes-spacing-20;
  }
}
.#{ui-pdp}-seller__compats,
.#{ui-pdp}-compats__compats {
  .ui-compats-header__container-message {
    width: 100%;

    .andes-message--quiet {
      .andes-message__buttons-container {
        button.andes-button.andes-message__secondary-button.andes-button--transparent {
          background: $andes-blue-150;
          color: $andes-blue-500;
        }
      }
    }
  }
}

.compats-specs__card {
  border-radius: 0 0 $border-radius-6 $border-radius-6;
  width: 100%;
}

.compats-specs__list {
  list-style-type: none;

  .compats-specs__item-list {
    position: relative;
    padding-left: $andes-spacing-8;

    &::before {
      position: absolute;
      left: 0;
      content: '\2022';
    }

    &:first-child {
      margin-top: 10px;
      margin-bottom: $andes-spacing-8;
    }
  }
}

.compats-specs__action-button-specs {
  &:hover {
    background: $andes-gray-040-solid;
  }

  .andes-button__content {
    gap: 4px;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.specs_component_message-visible {
  height: 100%;
  padding-bottom: 0;
}

/* stylelint-disable-next-line selector-class-pattern */
.specs_component_message-hidden {
  height: 75px;
  padding-bottom: 0;
  overflow: hidden;
  position: relative;
}

/* stylelint-disable-next-line selector-class-pattern */
.specs_component_transparent {
  position: absolute;
  height: 31px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, color(color_black_1000) -8.81%, #f5f5f5 80%);
}
