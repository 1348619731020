.#{$pdp-prefix}-specs-mobile {
  width: 100%;
  padding-bottom: 40px;
  border-bottom: 1px solid $andes-gray-100;
  margin-bottom: 40px;
  color: color(color_gray_dark);

  &__title {
    font-size: $font-size-20;
    font-weight: $font-weight-regular;
    margin-bottom: 24px;
  }

  &__subtitle {
    font-weight: $font-weight-regular;
    font-size: $font-size-18;
    margin-top: $andes-spacing-32;
  }

  &__features {
    margin-bottom: $andes-spacing-32;

    li {
      color: color(color_gray_dark);
      margin-bottom: $andes-spacing-8;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__info {
    .#{$pdp-prefix}-specs__title {
      font-size: $font-size-16;
      font-weight: $font-weight-semibold;
      padding: 0;
      padding-bottom: $andes-spacing-16;
    }
  }
}

.#{$pdp-prefix}-specs {
  margin-bottom: $andes-spacing-28;

  &__table {
    margin-bottom: $andes-spacing-12;
  }

  &__subtitle {
    font-size: $font-size-20;
    padding-bottom: $andes-spacing-24;
    margin-top: $andes-spacing-28;
  }

  &__list {
    padding-bottom: 0;
  }

  .#{$pdp-prefix}-container-actions {
    margin-top: $andes-spacing-32;

    .#{$pdp-prefix}-actions {
      padding: unset;
    }
  }
}

.#{$pdp-prefix}-specs__compats-disclaimer,
.ui-vpp-highlighted-specs__compats-disclaimer,
.ui-pdp-seller__compats-disclaimer {
  color: $andes-gray-550;
  font-weight: $font-weight-regular;
  font-size: $font-size-14;
  line-height: $font-size-18;
  margin-top: 10px;
  margin-left: $andes-spacing-4;
}
