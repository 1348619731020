$border-color-sr: #eee;

.container-advertising .ui-related-services {
  width: 320px;
}

.container-advertising.ui-related-services.loaded {
  border-bottom: 1px solid $border-color-sr;
  border-top: 1px solid $border-color-sr;
  width: 100%;
  padding: 40px 20px;
}

.ui-related-services.loaded.line {
  margin: 16px 0;
  border-bottom: 1px solid $border-color-sr;
}

.banner.ui-vpp-advertising.banner--hidden {
  display: none;
}

.container-advertising {
  width: 100%;
}

.title-advertising {
  font-size: $font-size-20;
  font-weight: $font-weight-regular;
  padding-bottom: 32px;
}

.banner.ui-vpp-advertising.loaded {
  text-align: center;
}

.container-advertising.ui-fullscreen-ad-pdp {
  padding: $andes-spacing-16;
  background-color: $andes-bg-primary;
}
