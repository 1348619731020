.#{$pdp-prefix-reviews}__actions {
  &__container {
    padding: 0 $andes-spacing-16;

    .ui-pdp-reviews__actions__button {
      margin-top: $andes-spacing-24;
    }
  }

  &__all-reviews {
    margin: $andes-spacing-32 0;
  }
}
