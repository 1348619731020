@import '../../../styles/variables';

.#{$pdp-prefix}-see-more {
  $self: &;

  width: 100%;

  &__modal {
    .andes-modal-title {
      font-weight: $font-weight-regular;
      font-size: $font-size-24;
      color: color(color_gray_dark);
    }

    .andes-modal__header,
    .andes-modal__content {
      padding-left: $andes-spacing-24;
      padding-right: $andes-spacing-24;
    }
  }

  &__action {
    width: 100%;
    margin-top: 32px;
  }

  .#{$pdp-prefix}-action-modal {
    display: block;
  }
}
