@import './review-summary';

.#{$pdp-prefix}-review {
  &__rating {
    font-size: $font-size-12;
    margin-right: $andes-spacing-4;
    position: relative;
    top: 0.5px;
  }

  &__amount {
    font-size: $font-size-12;
    margin-left: 1px;
  }

  &__ratings {
    .#{$pdp-prefix}-icon {
      width: 10px;
      height: 10px;
    }
  }
}
