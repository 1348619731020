.andes-modal__overlay--card {
  .#{$pdp-prefix}-question-modal__modal {
    width: 100%;
    margin: 0 $andes-spacing-24;
    min-height: 272px;
    max-height: 287px;

    &.modal-fixed-height {
      min-height: 247px;
      height: auto;
    }

    .andes-modal {
      &__header {
        padding: $andes-spacing-20 $andes-spacing-24 $andes-spacing-12;
      }

      &__title {
        font-size: 1.45em;
        line-height: 1.3;
      }
    }

    .ui-pdp-question-modal__subtitle {
      font-size: $font-size-16;
      line-height: $line-height-s;
      color: color(color_gray_dark);
      margin: 0 0 $andes-spacing-20;
      text-align: center;
    }

    .andes-form-control--textfield.andes-form-control--multiline .andes-form-control__field {
      min-height: 75px;
      max-height: 75px;
      color: color(color_gray_dark);
      line-height: 1.29;
      padding: $andes-spacing-12;
    }

    .andes-form-control--textfield.andes-form-control--multiline .andes-form-control__field,
    .andes-form-control--textfield.andes-form-control--multiline .andes-form-control__field::placeholder {
      font-size: $font-size-14;
    }

    // sass-lint:disable bem-depth
    .ui-pdp-qadb__question-form__form__actions {
      margin-bottom: 0;
      text-align: center;
    }
  }

  @media (width <= 320px) {
    .andes-modal-title {
      font-size: 1.07em;
    }

    .ui-pdp-question-modal__subtitle {
      font-size: $font-size-14;
    }
  }
}
