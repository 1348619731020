@import '@vpp-frontend-components/price/mobile';

.ui-pdp-price--fallback-boundary {
  padding: 16px;
}

// Do this in order to prevent scroll in all view
.ui-pdp-price__discounts-tags {
  .andes-carousel-free__list {
    width: 100px;
  }
}
