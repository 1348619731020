@import './denounce';

.#{$vpp-prefix}-denounce {
  width: 100%;
  padding: 24px 16px;
  display: block;

  &__info {
    padding-left: 0;
    display: inline-block;
  }

  &__action {
    display: inline-block;
  }

  &__code-internal {
    padding: 0;
    margin-bottom: $andes-spacing-8;

    &::after {
      display: none;
    }
  }
}
