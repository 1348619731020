.#{$pdp-prefix-reviews}__rating__summary {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__average {
    line-height: $line-height-ssm;
    margin-right: $andes-spacing-16;
  }

  &__description {
    display: inline-block;
  }

  &__rating {
    text-align: left;
    position: relative;
    top: 6px;
  }

  &__rating__star {
    width: 25.2px;
    height: 24px;
    margin-right: $andes-spacing-8;
  }

  &__label {
    font-size: $font-size-14;
    margin-top: 8px;
    line-height: 1.14;
    font-weight: $font-weight-regular;
    letter-spacing: normal;
  }
}
