.#{$vpp-prefix}-highlighted-specs__key-value-carousel {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: $andes-spacing-40;
    height: 100%;
    background: linear-gradient(to right, rgb(255 255 255 / 20%), $andes-white);
  }

  &__container {
    display: flex;
    overflow: auto hidden;
    padding: 0 $andes-spacing-40 0 $andes-spacing-16;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__list {
    display: flex;
    gap: $andes-spacing-32;
    margin-top: $andes-spacing-24;

    .#{$vpp-prefix}-highlighted-specs__key-value__labels__key-value {
      text-wrap: nowrap;
    }
  }
}
