@import './banner';

.ui-pdp-banner {
  .#{$pdp-prefix}-banner-container {
    padding: 0 $andes-spacing-12;
    margin-top: $andes-spacing-12;

    .#{$pdp-prefix}-banner {
      margin: 0;
    }
  }
}

.ui-pdp-banner__action-modal.ui-pdp-iframe-modal.ui-pdp-shipping-modal .andes-modal__scroll .andes-modal__content {
  padding: 0;
}

.ui-pdp-banner + div .ui-pdp-container__row.ui-pdp-container__row--returns-summary {
  margin-top: $andes-spacing-24;
}

.ui-pdp-generic-summary--banner {
  margin-top: $andes-spacing-12;
}
