.#{$pdp-prefix}-qadb__questions-list {
  &__action {
    margin-top: 24px;
  }

  &.ui-pdp-iframe {
    height: 100%;
  }

  &__question {
    margin-top: 16px;

    &:not(:first-of-type) {
      margin-top: 24px;
    }

    &__label {
      font-size: $font-size-16;
    }
  }

  &__answer-item__answer {
    font-size: $font-size-16;
  }

  &__answer-item__answered-by {
    font-size: $font-size-12;
  }

  &__see-more__link {
    font-size: $font-size-14;
  }
}

.#{$pdp-prefix}-qadb__questions-list__see-more__modal-content {
  .andes-modal__header {
    padding: 24px;

    &-title {
      font-size: 0;
    }
  }
}
