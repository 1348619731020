@import './compats-highlight';

.#{$compats-prefix}-highlight {
  width: 100%;
  white-space: nowrap;

  &__text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 300px;
    display: inline-block;
  }

  &__tooltip-compats {
    &::before {
      content: '';
      width: 11px;
      height: 11px;
      background: $andes-blue-500;
      position: absolute;
      top: -5px;
      left: 14px;
      transform: rotate(45deg);
      pointer-events: none;
    }

    .andes-tooltip-data__arrow {
      width: 11px;
      height: 11px;
      background-color: transparent;

      &::before {
        border-color: transparent;
        background-color: transparent;
      }
    }
  }
}

@media (width >= 768px) {
  .#{$compats-prefix}-highlight {
    margin: 0 0 $andes-spacing-4;
  }
}
