@import '../../../styles/variables';

.#{$vpp-prefix}-apparel-size {
  padding: 0 16px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* stylelint-disable-next-line selector-class-pattern */
  &.__only-fit-as-expected {
    justify-content: flex-start;
    margin-top: $andes-spacing-12;
    @media (width <= 375px) {
      text-overflow: ellipsis;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.__outside-variations {
    width: auto;
  }

  &__sizes {
    align-self: center;
    display: flex;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: 14px;
  }

  &__icon-ruler {
    margin: 0 5px;
    height: 24px;
    width: 15px;
  }

  &__label {
    margin: 5px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__fit-as-expected {
    font-size: $font-size-12;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:focus {
      outline: none;
    }
  }
}

.#{$vpp-prefix}-apparel-size__fit-as-expected__modal {
  .#{$vpp-prefix}-rating__level {
    grid-template-columns: 1fr 1fr 30px;
  }
}

@media (width <= 320px) {
  .#{$vpp-prefix}-apparel-size__fit-as-expected__modal {
    .#{$vpp-prefix}-rating__level {
      grid-template-columns: 1.5fr 1fr 30px;
    }
  }
}
